<template>
   <div id="status-bar" :class="loaded?'show':'hide'">
      <div class="items">
         <status-item v-for="(item, i) in items" :key="i" :item="item" @click="copyURL"/>
      </div>
      <input type="hidden" value="mc.varestorm.com" ref="input"/>
   </div>
</template>
<script>
import {gql} from "apollo-boost";
import StatusItem from "@/components/status/StatusItem";
import Icon from "@/components/Icon";

export default {
   name: "StatusBar",
   components: {Icon, StatusItem},
   data: () => ({
      now: new Date(),
      interval: null,
      server: {status: 'offline'},
      world: [],
      loaded: false,
      error: false,
      hasData: false,
   }),
   created() {
      setTimeout(this.doLoad, 2000)
      this.interval = setInterval(this.tick, 500)
   },
   beforeDestroy() {
      clearInterval(this.interval)
   },
   apollo: {
      server: {
         query: gql`query server {
                server {
                    status
                    startup
                    restart
                    version {
                        minecraft
                        max
                    }
                }
            }`,
         pollInterval: 30000,
         fetchPolicy: 'cache-and-network',
         error(err) {
            console.log(err)
            this.error = true
         },
         fetchResults: true
      },
      world: {
         query: gql`query world {
                world(name: "varestorm") {
                    name
                    difficulty
                    is_raining
                    is_storming
                }
            }`,
         pollInterval: 5000,
         fetchPolicy: "cache-and-network"
      }
   },
   watch: {
      server: {
         handler(server) {
            this.hasData = Object.keys(server).length > 0

            this.doLoad(true)
         },
         deep: true
      },
      restart: {
         handler(restart) {
            this.$store.commit("setRestart", restart)
         },
         immediate: true
      }
   },
   computed: {
      rawStatus() {
         if (!this.hasData) {
            return `onbekend`
         }
         return `${this.server.status ?? 'offline'}`.toLowerCase()
      },
      restart() {
         return this.server?.restart ?? 1000
      },
      startup() {
         return this.server?.startup ?? null
      },
      startupDate() {
         return new Date(this.startup)
      },
      time() {
         let time = ""
         if (this.hours > 0) {
            time += `${this.hours}h `
         }
         if (this.minutes > -1) {
            time += `${this.minutes}m `
         }
         if (this.seconds > -1 && this.minutes < 1) {
            time += `${this.seconds}s `
         }
         return time.substr(0, (time.length - 1))
      },
      hours() {
         return Math.floor(((this.milliseconds / (1000 * 3600)) % 60))
      },
      minutes() {
         return Math.floor(((this.milliseconds / (1000 * 60)) % 60))
      },
      seconds() {
         return Math.floor(((this.milliseconds / (1000)) % 60))
      },
      milliseconds() {
         return this.now.getTime() - this.startupDate.getTime()
      },
      version() {
         return (this.server?.version?.minecraft ?? "?.?.?")
      },
      max() {
         return (this.server?.version?.max ?? 0)
      },
      versionClass() {
         return `version-${this.version}`.replaceAll(".", "-")
      },
      versionIcon() {
         switch (true) {
            case this.version.indexOf('1.14') !== -1:
               return "dolphin"
            case this.version.indexOf('1.15') !== -1:
               return "bee"
            case this.version.indexOf('1.16') !== -1:
               return "pig"
            case this.version.indexOf('1.17') !== -1:
               return "mountain"
            case this.version.indexOf('1.18') !== -1:
               return "mountains"
            case this.version.indexOf('1.19') !== -1:
               return "campfire"
            default:
               return "shovel"
         }
      },
      status() {
         switch (this.rawStatus) {
            case 'ok':
               return `online`
            default:
               return `${this.rawStatus}`.toLowerCase()
         }
      },
      statusIcon() {
         switch (this.rawStatus) {
            case 'ok':
               return 'shield-check'
            case 'restarting':
               return 'rotate'
            case 'offline':
               return 'xmark'
            default:
               return 'question'
         }
      },
      difficulty() {
         return `${this.world.difficulty ?? ''}`.toLowerCase()
      },
      difficultyIcon() {
         switch (this.difficulty) {
            case 'hard':
               return 'swords'
            case 'normal':
               return 'sword'
            case 'easy':
               return 'shield'
            default:
               return 'question'
         }
      },
      isRaining() {
         return this.world?.is_raining ?? false
      },
      isStorming() {
         return this.world?.is_storming ?? false
      },
      weather() {
         if (this.isStorming) {
            return 'storming'
         }
         if (this.isRaining) {
            return 'raining'
         }
         return 'sunny'
      },
      weatherIcon() {
         if (this.isStorming) {
            return 'cloud-bolt'
         }
         if (this.isRaining) {
            return 'cloud-drizzle'
         }
         return 'sun-bright'
      },
      players() {
         return this.$store.getters?.players ?? []
      },
      online() {
         return this.players.filter(player => player.online === true)
      },
      count() {
         return this.online?.length ?? 0
      },
      playersIcon() {
         if (this.online === 1) {
            return 'user'
         }
         if (this.online === 2) {
            return 'user-group'
         }
         return 'users'
      },
      items() {
         return [
            {
               title: "mc.varestorm.com",
               text: this.status,
               styling: this.rawStatus,
               icon: this.statusIcon
            },
            {
               title: "version",
               text: this.version,
               styling: this.versionClass,
               icon: this.versionIcon
            },
            {
               title: this.online === 1? 'Player' : "players",
               text: `${this.count}/${this.max}`,
               styling: 'players',
               icon: this.playersIcon
            },
            {
               title: "difficulty",
               text: this.difficulty,
               styling: this.difficulty,
               icon: this.difficultyIcon
            },
            {
               title: "weather",
               text: this.weather ?? 'meatballs',
               styling: this.weather,
               icon: this.weatherIcon
            }
         ]
      }
   },
   methods: {
      doLoad(loaded = true) {
         this.loaded = loaded
         this.$emit('loaded', loaded)
      },
      copyURL() {
         let ip = this.$refs.input;
         console.log(ip.innerHTML)
         ip.select();
         document.execCommand("copy");
      },
      tick() {
         this.now = new Date()
      }
   }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/components/StatusBar"
</style>
