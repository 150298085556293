<template>
    <div id="players" class="box" :class="show? 'show' : 'hide'">
        <div class="wrapper">
            <div class="container">
                <div class="content" v-if="players.length > 0">
                    <div class="title">
                        <h2>The Regulars</h2>
                    </div>
                    <div class="list">
                        <player-item v-for="(player, p) in players" :key="p" :player="player" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {gql} from 'apollo-boost'
import PlayerItem from "@/components/PlayerItem";

export default {
    name: "PlayerBox",
    components: {PlayerItem},
    data: () => ({
        players: [],
        error: false,
        show: false
    }),
    apollo: {
        players: {
            query: gql`query players {
                players {
                    uuid
                    name
                    online
                    afk
                    avatar {
                        image
                    }
                }
            }`,
            pollInterval: 5000,
            fetchPolicy: 'cache-and-network',
            skip() {
                return !this.show
            }
        }
    },
    watch: {
        players: {
            handler() {
                this.$store.commit("setPlayers", this.players)
                console.log(this.players)
                if (!this.show){
                    setTimeout(this.doShow, 10)
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods:{
        doShow(){
            this.show = true
        }
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/components/PlayerBox"
</style>
