<template>
    <div class="status-item" @click="$emit('click')">
        <div :class="`icon ${styling}`">
            <icon :icon="icon" family="fal" :spin="icon === 'rotate'"/>
        </div>
        <div class="description">
            <h2 class="title">{{ title }}</h2>
            <span :class="`text ${styling}`">{{ text }}</span>
        </div>
    </div>
</template>
<script>
import Icon from "@/components/Icon";

export default {
    name: "StatusItem",
    components: {Icon},
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        title() {
            return this.item?.title ?? null
        },
        text() {
            return this.item?.text ?? null
        },
        icon() {
            return this.item?.icon ?? 'xmark'
        },
        styling() {
            return this.item?.styling ?? null
        },
    }
}
</script>
<style scoped>
</style>
