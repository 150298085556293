<template>
    <div class="player" :class="status">
        <div class="avatar">
            <img :src="image" :alt="`https://crafatar.com/avatars/${uuid}`">
        </div>
        <div class="title">
            <span class="name">{{ name }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "PlayerItem",
    props: {
        player: {
            type: Object,
            required: true,
            default: {
                name: "No one",
                online: false,
                afk: false,
                avatar: {
                    image: null,
                    timestamp: null
                }
            }
        }
    },
    computed: {
        uuid() {
            return this.player?.uuid ?? null
        },
        name() {
            return this.player?.name ?? "No one"
        },
        image() {
            return this.player?.avatar?.image ?? null
        },
        online() {
            return this.player?.online ?? false
        },
        afk() {
            return this.player?.afk ?? false
        },
        status() {
            if (this.online){
                if (this.afk){
                    return 'afk'
                }
                return 'online'
            }
            return 'offline'
        },
        statusText(){
            if (this.online){
                if (this.afk){
                    return 'Afk'
                }
                return 'Online'
            }
            return 'Offline'
        }
    }
}
</script>
<style lang="sass" scoped>
</style>
