<template>
    <div id="home">
        <status-bar/>
        <div class="banner" :style="{backgroundImage: `url('${image}')`}">
            <div class="overlay">
                <div class="container">
                    <div class="intro">
                        <h1>Hello there</h1>
                        <p>
                            If playing vanilla Minecraft is your thing, don't look any further and join this server!
                            <br>
                            Sadly, no open invitations. If you know the admin, send him a message privately.
                            <br>
                            <input value="mc.varestorm.com" ref="input" type="text" :style="{height: 0, border: 0, padding: 0, overflow: 'hidden'}">
                            <span class="buttons">
                                <button class="button copy" @click="doCopy">
                                    <icon :icon="icon"/>
                                    <span>{{ text }}</span>
                                </button>
                                <a class="button primary copy" href="https://map.varestorm.com" target="_blank">
                                    <icon icon="compass"/>
                                    <span>Open world map</span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="wrapper split">
                    <div class="intro">
                        <h1>About the server</h1>
                        <p>
                            The Varestørm server is a private whitelisted Minecraft server with a bunch of awesome people who love to play vanilla Minecraft.
                        </p>
                        <p>
                            We build things, grind up mobs, explore a lot of biomes and sometimes die doing these things...
                            <br>
                            What can I say, it's Minecraft!
                        </p>
                    </div>
                    <div class="image">
                        <img :src="imageDodge" alt="Custom Nova Wallpaper #dodged">
                    </div>
                </div>
                <div class="wrapper split">
                    <div class="intro">
                        <h1>Vanilla-ish</h1>
                        <p>
                            Okay, maybe not totally "vanilla".
                            We play with a few custom made plug-ins that allow us to set spawn-points and teleport across the world.
                            <br>
                            <br>
                            <strong>But that's it!</strong>
                            Everything else is Minecraft the way Notch intended it to be.
                        </p>
                    </div>
                    <div class="image">
                        <img :src="imageEnderman" alt="Custom Nova Wallpaper #endermanphobia2" class="primary">
                    </div>
                </div>
            </div>
        </div>
        <div class="players">
            <player-box/>
        </div>
    </div>
</template>
<script>
import Background from "@/assets/images/background.png"
import Dodge from "@/assets/images/dodge.jpeg"
import Ender from "@/assets/images/enderman.jpeg"
import PlayerBox from "@/components/PlayerBox";
import StatusBar from "@/components/StatusBar";
import Icon from "@/components/Icon";

export default {
    name: 'Home',
    components: {Icon, StatusBar, PlayerBox},
    data: () => ({
        image: Background,
        imageDodge: Dodge,
        imageEnderman: Ender,
        show: false,
        counter: 0,
        text: "Copy IP address",
        icon: "clipboard",
    }),
    mounted() {
        this.resetButton()
        setTimeout(this.doShow, 50)
    },
    methods: {
        doShow() {
            this.show = true
        },
        resetButton() {
            this.text = "Copy IP address"
            this.icon = "clipboard"
        },
        doCopy() {
            const ip = this.$refs.input.value
            if (!navigator.clipboard) {
                try {
                    this.$refs.input.focus()
                    this.$refs.input.select()
                    const bool = document.execCommand('copy')
                    this.$refs.input.blur()
                    if (bool) {
                        this.onCopy()
                    } else {
                        this.noCopy()
                    }
                } catch (e) {
                    console.error(e)
                    this.noCopy()
                }
                return;
            }
            navigator.clipboard.writeText(ip).then(this.onCopy).catch(this.noCopy)
        },
        onCopy() {
            this.text = 'Copied!'
            this.icon = 'check'
            setTimeout(this.resetButton, 2000)
        },
        noCopy() {
            this.text = 'Failed...'
            this.icon = 'xmark'
            setTimeout(this.resetButton, 2000)
        }
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/views/Home"
</style>
